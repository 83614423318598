<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete
                v-if="isDeleteButtonVisible"
                @deleteRecord="deleteRecord"
              />
              <app-button-close route-name="videoShow_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <input type="hidden" v-model="videoShow.id">
                    <div class="form-group">
                      <app-checkbox
                        v-model="videoShow.enabled"
                        id="enabled"
                        :label="$t('videoShow.enabled')"
                      >
                      </app-checkbox>
                    </div>
                    <app-input
                      v-model.trim="videoShow.title"
                      @blur="$v.videoShow.title.$touch()"
                      :error="$v.videoShow.title.$error"
                      id="videoShow_title"
                      :label="$t('videoShow.title')"
                    >
                    </app-input>
                    <app-input
                      disabled
                      v-model="videoShow.slug"
                      id="videoShow_slug"
                      :label="$t('videoShow.slug')"
                    ></app-input>
                    <app-input
                      v-model.trim="videoShow.subTitle"
                      @blur="$v.videoShow.subTitle.$touch()"
                      :error="$v.videoShow.subTitle.$error"
                      id="videoShow_sub_title"
                      :label="$t('videoShow.sub_title')"
                    >
                    </app-input>
                    <div class="form-group">
                      <label>{{ $t('videoShow.image') }}</label><br>
                      <app-media-editable
                        v-if="videoShow.imageInfo"
                        :media="videoShow.imageInfo"
                        @set-media="setImage"
                        @remove-media="removeImage"
                        :width="300"
                        :height="168"
                      ></app-media-editable>
                      <app-media-select-button
                        @set-media="setImage"
                        :selected-dam-media="videoShow.imageInfo"
                        show-drop-zone
                        data-test="videoShow_btn_select_image_from_dam"
                      ></app-media-select-button>
                      <app-media-upload-button
                        @set-media="setImage"
                        data-test="videoShow_btn_select_image_from_pc"
                      ></app-media-upload-button>
                      <app-media-url-button
                        @set-media="setImage"
                        data-test="videoShow_btn_select_image_from_url"
                      ></app-media-url-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Checkbox from '../../components/form/Checkbox'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import VideoShowModel from '../../model/VideoShow'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import MediaUrlButton from '../../components/shared/MediaUrlButton'
import MediaEditable from '../../components/shared/MediaEditable'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'VideoShowNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      videoShow: this._.cloneDeep(VideoShowModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.VIDEO_PLUS_TV_SHOW_PERMISSIONS
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    }
  },
  validations: {
    videoShow: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45)
      },
      subTitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        if (this.videoShow.imageInfo) {
          this.videoShow.image = this.videoShow.imageInfo.id
        }
        this.$store.dispatch('videoShow/create', this.videoShow)
          .then(() => {
            if (this.$store.getters['videoShow/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.videoShow = this.$store.getters['videoShow/detail']
              this.$router.push('/videoShow/' + this.videoShow.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['videoShow/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deleteRecord () {
      this.$store.dispatch('videoShow/deleteRecord', this.videoShow)
        .then(() => {
          if (this.$store.getters['videoShow/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/videoShow')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['videoShow/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      this.videoShow.imageInfo = medias[0]
    },
    removeImage () {
      this.videoShow.imageInfo = null
    }
  }
}
</script>
